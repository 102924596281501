<script lang="ts" setup>
  import {
    GrModal,
    GrModalClose,
    GrModalContent,
    GrModalFooter,
    GrModalHeader,
  } from "@/components/GrComponents";

  import { GrButton } from "@/components/GrComponents";
  import { ref, onMounted } from "vue";
  import { useStore } from "@/utils/useStore";
  import { useRoute, useRouter } from "vue-router/composables";

  const isModalOpen = ref(false);
  const loading = ref(false);
  const canClose = ref(true);
  const size = ref("lg");
  const router = useRouter();
  const store = useStore();

  function changeModal(vModelValue) {
    isModalOpen.value = vModelValue;
  }

  /**
   * redireciona para a pagina do evento
   */
  function goToDivulgationPage()
  {
    router.push({ name: "dsa" });
  }

  /**
   * Cria o cookie que ja viu o modal
   */
  function setCookie(name, value, days)
  {
    let expires = "";
    if (days)
    {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  /**
   * Recupera o cookie
   */
  function getCookie(name)
  {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /**
   * Verifica se a flag do modal existe
   */
  async function showModalEvent()
  {
    const flags = store.getters["getFlags"];
    if (flags.includes("modal_event"))
      return true;
    else
      return false;
  }

  onMounted(() =>
  {
    const alertExpiryDate = new Date("2025-07-31");
    const currentDate = new Date();

    if (showModalEvent()) {
      if (!getCookie("modalSeen") && currentDate <= alertExpiryDate)
      {
        // show modal
        const button = document.getElementById("btnDSA");
        button.click();
        setCookie("modalSeen", true, 365);
      }
    }
  });
</script>

<template>
  <GrModal
    typeTransition="up"
    v-model="isModalOpen"
    :close="canClose"
    @update:modelValue="changeModal"
    renderTriggerTo="#open"
  >
    <template #open>
      <GrButton id="btnDSA" v-show="false"></GrButton>
    </template>

    <GrModalContent :size="size" :loading="loading">
      <GrModalHeader> DSA </GrModalHeader>

      <div class="content-modal">
          <img
          src="@/assets/img/banner/BANNER_DSA.png"
          srcset="@/assets/img/banner/BANNER_DSA.png"/>
      </div>

      <GrModalFooter>
        <GrModalClose>
            <GrButton @click="goToDivulgationPage" class="color-btn" variant="primary">Saiba mais → </GrButton>
        </GrModalClose>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
  .custom-modal-content
  {
    max-width: 830px;
  }

  #btnDSA
  {
    margin-left: 100px;
  }

  .content-modal
  {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: 430px;
    height: 100%;
    width: 100%;
  }

  .content-modal img
  {
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 20px;
  }

  .color-btn
  {
    background-color: #00B07F;
  }
</style>
