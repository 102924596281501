export default {
    //    productAmount: state => state.offers.filter(item => item.default).pop()?.amount || 0,
    productAmount: state => state.baseAmount || 0,
    product: state => state.product || null,
    hasProduct: state => !!state.product || null,
    attachments: state => state.product.attachments || [],
    productId: state => state.product?.id || null,
    productName: state => state.product?.name || "",
    productMethods: state => state.product?.method || "",
    productBeforeEdit: state => state.productBeforeEdit || {},
    getProductStartDate: state => state.product?.start_date || null,
    getProductStartTime: state => state.product?.start_time || null,
    getProductEndDate: state => state.product?.end_date || null,
    getProductEndTime: state => state.product?.end_time || null,
    getProductZipCode: state => state.product?.address?.zip_code || "",
    getProductNumber: state => state.product?.address?.number || "",
    getProductStreet: state => state.product?.address?.street || "",
    getProductNeighborhood: state => state.product?.address?.neighborhood || "",
    getProductCity: state => state.product?.address?.city || "",
    getProductState: state => state.product?.address?.state || "",
    getProductComplement: state => state.product?.address?.complement || "",
    getProductLocation: state => state.product?.location || "",
    getProductAreNonTransferable: state => state.product?.are_non_transferable || false,
    productStatus: state => state.product?.status_product || "",
    categories: state => state.categories || [],
    offerRedirect: state => state.product.offer_redirect || [],
    offers: state => state.offers || [],
    defaultOffer: state => state.defaultOffer || {},
    affiliateLink: state => state.affiliateLink,
    hideUpdateButton: state => state.hideUpdateButton,
    coSellers: state => state.product.co_sellers,
    getCoSellersPercentage: state => state.coSellersPercentage,
    productChecklist: state => state.product.product_checklist || [],
    customThankYouPages: state => state.product.custom_thank_you_pages || [],
    customThankYouPagesLocal: state => state.custom_thank_you_pages || [],
    fiscalCenterSetting: state => state.product.fiscal_center_setting || {},
    callValidateFields: state => state.callValidateFields || false,
    callValidateFieldsAddressEvent: state => state.callValidateFieldsAdrressEvent || false,
    callValidateFieldsDateEvent: state => state.callValidateFieldsDateEvent || false,
    callValidateInstallmentBoleto: state => state.validateInstallmentBoleto || false,
    allowSmartSale: state => state.allowSmartSale || false,
    getExportSales: state => state.exportSales || false,
    allowCustomPeriod: state => state.product.allowCustomPeriod || false,
    getAllowFreeOffers: state => state.allow_free_offers || null,
    getKycPersonalChecklist: state => state.kyc_personal_checklist || null,
    getErrorCreateCelcoin: state => state.errorCreateCelcoin || [],
    getErrorKycCelcoinPF: state => state.errorKycCelcoinPF || [],
    getErrorKycCelcoinPJ: state => state.errorKycCelcoinPJ || [],
    getEnableInvoiceItems: state => state.product.enable_invoice_items || false,
    getItemsForInvoice: state => state.product.items_for_invoice || [],
    getNeedOfferLoading: state => state.need_offer_loading,

    // Loadings
    isLoading: state => state.isLoading,
    saleExpressRedirectLoading: state => state.saleExpressRedirectLoading,
    affiliateLoading: state => state.affiliateLoading,
    listLoading: state => state.listLoading,

    // Errors
    errorInFetchProduct: state => state.errorInFetchProduct,

    // Payment
    getMethodsPayment: state => state.methodsPayment,
    getCurrencyPayment: state => state.currencyPayment,

    getEventFildsResult: state => state.eventFildsResult,

    // Content
    getWebhooks: state => state.webhooks
}
