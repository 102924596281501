<template>
  <div class="d-flex flex-column align-items-center text-center">
    
    <BaseHead 
      :title="$t('views.seller.contracts_new.modal.components.text_009')"
    >
      <img src="@/assets/img/icons-contracts/export.svg" alt="export" />
    </BaseHead>

    <div class="export-details w-100">
      <h5 class="details-title">{{ $t('views.seller.contracts_new.modal.components.text_010') }}</h5>
      <div class="details-info d-flex justify-content-between">
        <p class="details-label">{{ $t('views.seller.contracts_new.modal.components.text_011') }}</p>
        <p class="details-value">{{ email }}</p>
      </div>
      <div class="details-info d-flex justify-content-between">
        <p class="details-label">{{ $t('views.seller.contracts_new.modal.components.text_012') }}</p>
        <p class="details-value fields">
          {{ filtersNames }} 
        </p>
      </div>
      <div class="details-info d-flex justify-content-between">
        <p class="details-label">{{ $t('views.seller.contracts_new.modal.components.text_013') }}</p>
        <p class="details-value">.{{ fileType }}</p>
      </div>
    </div>

    <button 
      class="btn-continue" 
      @click="nextStep" 
    >
      {{ $t('views.seller.contracts_new.modal.components.btn.text_002') }}
    </button>
  </div>
</template>

<script>
import ContractExportFileType from './ContractExportFileType.vue';
import ContractSuccessExport from './ContractSuccessExport.vue';
import BaseHead from './baseHead.vue';
import { markRaw } from 'vue'; 

export default {
  components: {
    BaseHead,
  },
  data() {
    const store = this.$store.getters.getDataExportContracts

    return {
      email: store.email,
      filters: store.filters,
      fileType: store.format,
    }
  },
  computed: {
    filtersNames() {
      return this.filters.map(item => item.name).join(',');
    }
  },
  methods: {
    async nextStep() {
      this.$store.commit('updateLoadingExport', true)
      const params = this.getFieldsFilters()
      const dataExport = this.setDataExport()
      const fileType = this.setTypeFile()
      const email = this.setEmail();

      const dataUrl = `${params}&${dataExport}&${fileType}&${email}`

      const exportFile = await this.$store.dispatch("exportContract", dataUrl)

      this.$store.commit('updateLoadingExport', false)
      if(exportFile) {
        this.$store.dispatch('updateComponentActive', markRaw(ContractSuccessExport));
      }

      this.$bvToast.toast(this.$t('views.seller.sales.modal_export.text_125'), {
        title: 'Contrato',
        variant: 'danger',
        autoHideDelay: 5000,
        appendToast: true
      });
    },
    setEmail() {
      const email = `client_email=${encodeURIComponent(this.email)}`;

      return email;
    },
    setTypeFile() {
      const type = `fileType=${encodeURIComponent(this.fileType)}`;

      return type;
    },
    setDataExport() {
      const filters = this.filters;
      const params = {};

      filters.forEach((filter, index) => {
        params[`export[${index}][id]`] = filter.id;
        params[`export[${index}][name]`] = filter.name;
      });

      const dataFilters = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

      return dataFilters;
    },
    getFieldsFilters() {
      const fields = this.$store.getters.getFiltersAppliedContracts;
      const params = {};

      fields.forEach(field => {
        if (field.value) {
          params[field.key] = field.value;
        }
      });

      const queryString = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

      return queryString;
    }
  },
  beforeMount() {
    this.$store.dispatch('updateLastComponentActive', markRaw(ContractExportFileType));
  },
};
</script>

<style scoped>
.export-details {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  max-width: 120% !important;
  width: 100% !important;
  min-width: 100% !important;
}

.details-title {
  color: var(--old-800, #003B36);
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
}

.details-label {
  font-size: 0.875rem;
  color: var(--gray-200);
}

.fields {
  width: 60%;
  text-align: right;
  white-space: normal;
  word-wrap: break-word;
  display: block;
}

.details-value {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--old-800);
  max-width: 100%;
  text-align: right;
  white-space: normal;
  word-wrap: break-word;
  display: block;
}

</style>
