<template>
  <div class="d-flex flex-column align-items-center text-center">
    
    <BaseHead 
      :title="$t('views.seller.contracts_new.modal.components.text_014')"
      :subTitle="$t('views.seller.contracts_new.modal.components.text_015')"
    >
      <img src="@/assets/img/icons-contracts/confirm-export.svg" alt="success" />
    </BaseHead>

    <button 
      class="btn-continue" 
      @click="nextStep"
    >
      <img src="@/assets/img/icons-contracts/export-success.svg" alt="success" class="icon-success" />
      <span>
        {{ $t('views.seller.contracts_new.modal.components.btn.text_003') }}
      </span>
    </button>
  </div>
</template>

<script setup>
import BaseHead from './baseHead.vue';
import { useStore } from "@/utils/useStore";
import { markRaw, onBeforeMount, defineEmits } from 'vue';
import ContractExportFileType from './ContractExportFileType.vue';

const store = useStore()
const emits = defineEmits(['close-modal']);

function nextStep() { 
  emits('close-modal');
}

onBeforeMount(() => {
  store.dispatch('updateStepBack', false)
  store.dispatch('updateLastComponentActive', markRaw(ContractExportFileType))
})

</script>

<style scoped>
.btn-continue {
  width: 315px;
  height: 55px;
  flex-shrink: 0;

  border-radius: 10px;
  background: var(--primary-600);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
}

.btn-continue:hover {
  background: var(--primary-600);
}

.btn-continue span {
  color: var(--gray-white) !important;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.icon-success {
  width: 20px;
  height: 20px;
}

</style>
