<script setup>
import Vue, { computed } from "vue";
import { getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "@/utils/useStore";
import { useI18n } from "@/utils/useI18n";
import { serviceIntegrationX } from "@/main.js";

const instance = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const store = useStore();
const i18n = useI18n();

import iconSideChevron from "@/components/GrComponents/sidebar/icons/iconChevron.vue";

const props = defineProps({
  name: {
    type: String,
    default: "Defina o nome a ser exibido",
  },
  traduction: {
    type: String,
    default: "",
  },
  active: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  routes: {
    type: Array,
    default: [],
  },
  hover: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["changeItem", "changeSubItem", "click"]);

const translateName = computed(() => {
  return props.traduction && props.traduction.length
    ? i18n.t(props.traduction)
    : props.name;
});

const isActiveDashboard = computed(() => {
  const currentRoute = route.name;

  if (currentRoute === "Dashboard" && props.name === "Vendas") {
    return false;
  }

  return props.active;
});


const getFlags = computed(() => store.getters.getFlags);

function sendActiveItem(name) {
  emit("changeItem", name);
}

function sendActiveSubItem(subItem) {
  if (subItem.name === "Área de membros") {
    eventLoginClub();
    return;
  }
  emit("changeSubItem", subItem);
}

function emitClick(event) {
  if (event.keyCode === 13 || event.key === "Enter") {
    emit("changeItem", props.name, true);
  }
}

function getRouterLink(routeName, index) {
  const link = { name: routeName };

  const existingQueries = route.query || {};

  if (!!props.routes[index].query) {
    link.query = { ...existingQueries, action: props.routes[index].query };
  } else {
    link.query = { action: props.routes[index].query };
  }

  return link;
}

function eventLoginClub() {
  const that = instance.proxy;

  Vue.swal({
    title: that.$t("side.text_1973"),
    text: that.$t("side.text_1971"),
    type: "info",
    showCancelButton: true,
    confirmButtonText: "Entrar no Club",
    cancelButtonText: that.$t("side.text_1975"),
    customClass: "sweet-container",
    confirmButtonClass: "button button-black mt-3 mb-3",
    cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
    buttonsStyling: false,
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch("clubLogin").catch((err) => {});
    }
    if (result.dismiss == "cancel" && route.name !== "Products") {
      router.push({ name: "Products" });
    }
  });
}

function redirectToSales() {
  if(!getFlags.value.includes('leads_unification')) {
    return router.push({name: 'Leads'});
  };
  serviceIntegrationX({ id: 'check-account' }, true).then(async resp => {
    if (resp && resp.hasGAccount && resp.rdrk) {
      return window.open(`${process.env.VUE_ADM_SALES}adm/login?rdrk=${resp.rdrk}&route=leads`, "_self");
    }

    router.push({name: 'Leads'});
  }).catch(() => {
    router.push({name: 'Leads'});
  });
}

function validateAccess(access, name){
  if (name === "Contratos") {
    const flags = store.getters["getFlags"];
    return flags.includes("contract_recurrence");
  }

  if (access) {
    return true;
  }

  return false;
}

</script>
<template>
  <div class="items" @keydown="emitClick">
    <div
      :ref="props.name"
      tabindex="0"
      :class="{ active: isActiveDashboard }"
      class="item"
      @click="sendActiveItem(props.name)"
    >
      <div>
        <div>
          <slot />
        </div>
        <h1 :class="{ hover: props.hover }">{{ translateName }}</h1>
      </div>
      <iconSideChevron :active="isActiveDashboard" />
    </div>
    <Transition name="accordion">
      <div
        v-show="props.open"
        :class="{ animation: props.open }"
        class="sub-items"
      >
        <div
          v-if="validateAccess(subItem.access,subItem.name)"
          v-for="(subItem, index) in props.routes"
          :key="index"
          @click="sendActiveSubItem(subItem)"
        >        
          <a 
            v-if="subItem.translation === 'side.text_1977'" 
            role="button"
            :class="{ active: subItem.active, hover: props.hover }"
            @click="redirectToSales()"
          >
            {{ i18n.t(subItem.translation) }}
          </a>
          <router-link
            v-else
            :class="{ active: subItem.active, hover: props.hover }"
            :to="getRouterLink(subItem.route, index)"
          >
            {{ i18n.t(subItem.translation) }}
          </router-link>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.accordion-enter-active {
  transition: opacity 0.3s ease;
}

.accordion-enter-from,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.accordion-enter-to,
.accordion-leave-from {
  max-height: 1000px;
  opacity: 1;
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  white-space: nowrap;

  &:focus-visible {
    transition: none;
    outline: 1.5px solid var(--old-500);
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: var(--spacing-4-25);
    transition: var(--transition);
    border-radius: var(--radius-lg);
    height: 48px;

    &:hover {
      background: var(--gray-10);
    }

    &:focus-visible {
      transition: none;
      outline: 1.5px solid var(--old-500);
    }

    div {
      display: flex;
      align-items: center;
      gap: var(--spacing-6);
    }

    h1 {
      color: var(--gray-300);
      font-size: var(--font-md);
      font-weight: var(--weight-medium);
      transition: var(--transition);
      overflow: hidden;
      text-overflow: ellipsis;

      &.hover {
        animation: fadeEnter 1s ease;
      }

      &:not(.hover) {
        animation: fadeExit 0.3s forwards;
      }

      @media (max-width: 768px) {
        animation: none !important;
      }
    }

    &.active {
      background-color: var(--old-50);

      h1 {
        color: var(--old-500);
        font-weight: var(--weight-semibold);
      }
    }
  }

  .sub-items {
    border-left: 1px solid var(--gray-50);
    display: grid;
    gap: var(--spacing-6);
    width: 100%;
    margin-top: 15px;
    padding-left: 35px;
    margin-left: var(--spacing-5-7);
    user-select: none;

    .active {
      color: var(--old-500);
      font-weight: var(--weight-medium);

      &:hover {
        color: var(--old-500);
      }
    }

    a {
      color: var(--gray-300);
      font-size: var(--font-md);
      font-weight: var(--weight-medium);
      transition: var(--transition);
      text-decoration: none;
      border-radius: var(--radius);
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      &.hover {
        animation: fadeEnter 1s ease;
      }

      &:not(.hover) {
        animation: fadeExit 0.3s forwards;
      }

      &:hover {
        color: var(--old-400);
      }

      &:focus-visible {
        transition: none;
        outline: 1.5px solid var(--old-500);
      }

      :visited {
        color: inherit;
      }
    }
  }
}
@keyframes fadeEnter {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: visible;
  }
}

@keyframes fadeExit {
  0% {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
  70% {
    width: 0;
    max-width: 0;
    overflow: hidden;
  }

  100% {
    opacity: 0;
  }
}
</style>
