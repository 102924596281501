<script setup>
import { computed, inject , ref,getCurrentInstance} from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import Vue from "vue";
import { useI18n } from "@/utils/useI18n";
import { useStore } from "@/utils/useStore";
import { EventBus , serviceIntegrationX } from "@/main.js";
import {
  GrDropDownContent,
  GrDropDownSeparator,
  GrParagraph,
} from "@/components/GrComponents";

const toggleDropdown = inject("toggleDropdown");
const AllRoutesSidebar = inject("AllRoutesSidebar");
const vm = getCurrentInstance();

if (!toggleDropdown) {
  throw new Error("toggleDropdown not provided");
}

if (!AllRoutesSidebar) {
  throw new Error("AllRoutesSidebar not provided");
}

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const store = useStore();

const props = defineProps({
  align: { type: String, default: "left" },
  size: { type: String, default: "lg" },
});

const isClient = computed(() => {
  return store.getters["getIsClient"];
});

const isHeaven = computed(() => {
  return store.getters["getIsHeaven"];
});

const validateRouteWithdrawalQuery = computed(() => {
  if (
    route.name === "Statements" &&
    route?.query?.action === "withdrawal-request"
  )
    return true;
  return false;
});

const integrationG = async () => {
  serviceIntegrationX({ id: 'check-account' }).then(async resp => {
    if(resp && (resp.ecosystemGreenn || !resp.hasGAccount) ){
      if(resp.ecosystemGreenn){
        store.dispatch("setintegrationGreennSales",resp)
      }
      vm.proxy.$bvModal.show('integration-x')
    } else if (resp && resp.hasGAccount && resp.rdrk) {
      window.open(`${process.env.VUE_ADM_SALES}adm/login?rdrk=${resp.rdrk}`, "_blank");
    }
  }).catch(err => {
    console.error('erro',err);
  });
};

const isShowSales = computed(() => {
  const salesRoute = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Sales"
  );
  return salesRoute ? salesRoute.access && !isClient.value : true;
});

const isShowProducts = computed(() => {
  const salesProducts = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Products"
  );
  return salesProducts ? salesProducts.access && !isClient.value : true;
});

const isShowLinks = computed(() => {
  const salesLinks = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Links"
  );
  return salesLinks ? salesLinks.access && !isClient.value : true;
});

const isShowCheckouts = computed(() => {
  const salesCheckouts = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Checkouts"
  );
  return salesCheckouts ? salesCheckouts.access && !isClient.value : true;
});

const isShowWithdrawAndExtract = computed(() => {
  return !store.getters.setPanel && !isClient.value;
});

const isEmptyShortcuts = computed(() => {
  if (isShowWithdrawAndExtract.value) return true;

  let validateAll = false;

  if (
    isShowSales.value &&
    isShowProducts.value &&
    isShowLinks.value &&
    isShowCheckouts.value
  ) {
    return validateAll;
  }

  if (
    isShowSales.value ||
    isShowProducts.value ||
    isShowLinks.value ||
    isShowCheckouts.value
  ) {
    return !validateAll;
  }
  if (
    !isShowSales.value ||
    !isShowProducts.value ||
    !isShowLinks.value ||
    !isShowCheckouts.value
  ) {
    return validateAll;
  }

  return !validateAll;
});

const linkClub = () => {

      Vue.swal({
        title: 'Acessar o GreenClub',
        text: 'Para acessar, primeiro é necessario associar um produto ao Club',
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Club",
        cancelButtonText: 'Associar produto',
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          vm.proxy.$store.dispatch("clubLogin").catch(function () {});
        }
        if (result.dismiss == "cancel") {
          router.push("/produtos");
        }
      });

}

const linkHeaven = computed(() => {
  return process.env.VUE_ADM_HEAVEN_PAGE
    ? process.env.VUE_ADM_HEAVEN_PAGE
    : "https://adm.heaven.me/";
});

const linkEnvios = computed(() => {
  return process.env.VUE_ENVIOS_API_HOST
    ? process.env.VUE_ENVIOS_API_HOST
    : "https://envios.greenn.com.br/login";
});
</script>
<template>
  <GrDropDownContent :align="props.align" size="lg">
    
    <div class="container-apps" v-if="!$store.getters.setPanel">
      <div class="apps">
        
        <GrParagraph
          class="gr-apps-text"
          color="gray-400"
          font-size="base"
          weight="semibold"
          >{{ i18n.t("side.text_1993") }}</GrParagraph
        >
        <div class="apps-grid">
          <div class="gr-app">
            <div class="icon greenn">
              <img src="@/assets/img/gr-icons/greenn-app.png" alt="app" />
            </div>
            <GrParagraph color="gray-400" font-size="xsm" weight="normal"
              >Greenn Adm</GrParagraph
            >
          </div>
          <div class="gr-app" @click="integrationG">
            <div class="icon greenn-sales">
              <img
              src="@/assets/img/gr-icons/greenn_sales-app.png"
              alt="app"
              />
            </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal"
                >Greenn Sales</GrParagraph
              >
          </div>
          <div class="gr-app" @click="toggleDropdown">
            <a href="javascript:;" @click="linkClub()">
              <div class="icon greenn-club">
                <img
                src="@/assets/img/gr-icons/club-app.png"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal"
                >Greenn Club</GrParagraph
              >
            </a>
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="isHeaven">
            <a :href="linkHeaven" target="_blank">
              <div class="icon heaven">
                <img
                src="@/assets/img/gr-icons/heaven-app.png"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal"
                >Heaven</GrParagraph
              >
            </a>
          </div>
          <!-- <div class="gr-app" @click="toggleDropdown">
            <a :href="linkEnvios" target="_blank">
              <img
                src="@/assets/img/gr-icons/envios-app.svg"
                alt="app"
                class="envios"
              />
              <GrParagraph color="gray-400" font-size="xsm" weight="normal"
                >Envios</GrParagraph
              ></a
            >
          </div> -->
        </div>
      </div>
    </div>

    <GrDropDownSeparator v-if="isEmptyShortcuts || !$store.getters.setPanel" />
    <div class="container-apps" v-if="isEmptyShortcuts">
      <div class="apps">
        <GrParagraph
          class="gr-apps-text"
          color="gray-400"
          font-size="base"
          weight="semibold"
          >{{ i18n.t("side.text_1994") }}</GrParagraph
        >
        <div class="apps-grid">
          <div class="gr-app" @click="toggleDropdown" v-if="isShowSales">
            <router-link :to="{ name: 'Sales' }" class="app-shortcut">
              <div class="icon">
                <img src="@/assets/img/gr-icons/sales-shortcut.svg" alt="app" />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1952")
              }}</GrParagraph></router-link
            >
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="isShowProducts">
            <router-link :to="{ name: 'Products' }" class="app-shortcut">
              <div class="icon">
                <img
                src="@/assets/img/gr-icons/products-shortcut.svg"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1954")
              }}</GrParagraph>
            </router-link>
          </div>
          <div
            class="gr-app"
            @click="toggleDropdown"
            v-if="isShowWithdrawAndExtract"
          >
            <router-link
              :to="{
                name: 'Statements',
                query: { action: 'withdrawal-request' },
              }"
              class="app-shortcut"
            >

            <div class="icon">
              <img
              src="@/assets/img/gr-icons/withdraw-shortcut.svg"
              alt="app"
              />
            </div>

              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1995")
              }}</GrParagraph>
            </router-link>
          </div>

          <div
            class="gr-app"
            @click="toggleDropdown"
            v-if="isShowWithdrawAndExtract"
          >
            <router-link
              :class="{ statementsQuery: validateRouteWithdrawalQuery }"
              :to="{ name: 'Statements' }"
              class="app-shortcut"
            >
            <div class="icon">
              <img src="@/assets/img/gr-icons/extract-shortcut.svg" alt="app" />
            </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1956")
              }}</GrParagraph>
            </router-link>
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="isShowLinks">
            <router-link :to="{ name: 'Links' }" class="app-shortcut">
              <div class="icon">
                <img src="@/assets/img/gr-icons/links-shortcut.svg" alt="app" />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1996")
              }}</GrParagraph>
            </router-link>
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="isShowCheckouts">
            <router-link :to="{ name: 'Checkouts' }" class="app-shortcut">
              <div class="icon">
                <img
                src="@/assets/img/gr-icons/checkouts-shortcut.svg"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">{{
                i18n.t("side.text_1959")
              }}</GrParagraph>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </GrDropDownContent>
</template>

<style scoped lang="scss">
.container-apps {
  padding: var(--spacing-4);
  width: var(--w-full);

  .apps {
    display: grid;
    align-items: center;
    gap: var(--spacing-3);

    .gr-apps-text {
      padding-left: var(--spacing-3) !important;
    }

    .apps-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-3);

      @media (max-width: 350px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .gr-app {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        gap: var(--spacing-1-5);

        .icon{
          width: var(--w-16);
          height: var(--w-16);

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.3s;

          border: 1px solid transparent;
          border-radius: var(--radius-lg);

          img{
            width: 50%;
          }
          
          &.greenn-club {
            img{
              width: 50%;
            }
            background-color: var(--old-50);
            &:hover {
              border: 1px solid var(--primary-600) !important;
              border: 1px solid var(--old-300) !important;
            }
          }
          
          &.greenn-sales {
            background-color: var(--primary-50);
            &:hover {
              border: 1px solid #a1cd3a !important;
            }
          }
          
            &.greenn {
              background-color: var(--old-50);
              border: 1px solid var(--primary-600) !important;
            }

            &.heaven,
            &.envios {
              background-color: var(--blue-50);
              &:hover {
                border: 1px solid var(--blue-500) !important;
              }
            }
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          gap: var(--spacing-1-5);
          text-decoration: none;
          border-radius: var(--radius-lg);

            .icon{
              width: var(--w-16);
              height: var(--w-16);
              display: flex;
              align-items: center;
              justify-content: center;

              background-color: var(--gray-10);
              transition: all 0.3s;

              img{
                width: 100%;
              }
            }

          img {
            transition: var(--transition);
            border: 1px solid transparent;
            border-radius: var(--radius-lg);
            &.greenn-club {
              &:hover {
                border: 1px solid var(--old-300) !important;
              }
            }

            &.heaven,
            &.envios {
              &:hover {
                border: 1px solid var(--blue-500) !important;
              }
            }
          }

          &.app-shortcut {
            &.router-link-active img {
              border: 1px solid var(--dark-900);
              &:hover {
                border: 1px solid var(--dark-900);
              }
            }

            &.statementsQuery img {
              border: 1px solid transparent !important;
            }
            img {
              &:hover {
                border: 1px solid var(--gray-300);
              }
            }
          }
        }
      }
    }
  }

  .greenn-sales {
    &:hover {
      border: 1px solid #a1cd3a !important;
      border-radius: var(--radius-lg);
    }
  }
}
</style>
