import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'
import isAmbassador from '@/router/middleware/isAmbassador'
import isHeaven from '@/router/middleware/isHeaven'
import isSeller from '@/router/middleware/isSeller'
import isIndicationEnable from '@/router/middleware/isIndicationEnable'


export default [{
    path: '/',
    name: 'Login',
    component: () => import('../../views/Seller/Login.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/login/integration/',
    name: 'Login Integração',
    component: () => import('../../views/Seller/Login.vue'),
    beforeEnter: middleware([unauthentication]),
    props: route => ({
      redirect_url: route.query.redirect_url,
      client_id: route.query.client_id,
      hash: route.query.hash
    })
  },
  {
    path: '/boas-vindas',
    name: 'Boas Vindas',
    component: () => import('../../views/Seller/SellerOnboarding.vue'),
    props: true,
    meta: {
      sidebar: false
    }
  },
  {
    path: '/codigo-de-acesso',
    name: 'CodigoDeAcesso',
    component: () => import('../../views/Seller/AccessCode.vue'),
    props: route => ({ id: route.query.user_id }),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/verificar-email',
    name: 'VerificaEmail',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/verificar-email/:hash',
    name: 'VerificaEmailHash',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn',
    name: 'LoginGreenn',
    component: () => import('../../views/Seller/Logingreen.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn-afiliado',
    name: 'afiliado',
    component: () => import('../../views/Seller/AffiliateInvitation.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/registro',
    name: 'Register',
    component: () => import('../../views/Seller/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/register-seller',
    name: 'Seller-Register',
    component: () => import('../../views/Seller/RegisterSeller.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/recrutamento/:hash',
    name: 'Recruitment',
    component: () => import('../../views/Seller/Recruitment.vue'),
  },
  {
    path: '/download/:product_id/:sale_id/:hash',
    name: 'DownloadFiles',
    component: () => import('../../views/Client/DownloadFiles.vue'),
  },
  {
    path: '/avaliacao/',
    name: 'Avaliacao',
    component: () => import('../../views/Client/Avaliacao.vue'),
  },
  {
    path: '/avaliacao/atendente',
    name: 'AttendantEvaluation',
    component: () => import('../../views/Client/AttendantEvaluation.vue'),
  },
  {
    path: '/recuperar-senha/:token?',
    name: 'Seller-Password-Recovery',
    component: () => import('../../views/Seller/PasswordRecovery.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../../views/Seller/Dashboard.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/minhas-compras',
    name: 'MyPurchases',
    component: () => import('../../views/Seller/MyPurchases.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/vendas/',
    name: 'Sales',
    component: () => import('../../views/Seller/Sales.vue'),
    // beforeEnter: middleware([authentication]),
  },
  {
    path: '/oportunidades/',
    name: 'Opportunities',
    component: () => import('../../views/Seller/Opportunities.vue'),
    beforeEnter: middleware([authentication]),
  },

  {
    path: '/contratos',
    name: 'Contracts',
    component: () => import('../../views/Seller/RecurrenceSubscription.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/contractsNew',
    name: 'ContractsNew',
    component: () => import('../../views/Seller/Contracts/List.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/contractsNew/:contract_id',
    name: 'ContractsNewDetails',
    component: () => import('../../views/Seller/Contracts/Details.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/produtos',
    name: 'Products',
    component: () => import('../../views/Seller/Products.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/novo-produto',
    component: () => import('../../views/Seller/CreateProduct/index.vue'),
    beforeEnter: middleware([authentication, isSeller]),
    children: [
      {
        path: '',
        name: 'CreateProductTypes',
        component: () => import('../../views/Seller/CreateProduct/Types.vue'),
        beforeEnter: middleware([authentication, isSeller]),
      },
      {
        path: '/novo-produto/:type?/:product_id?',
        name: 'CreateProductSteps',
        component: () => import('../../views/Seller/CreateProduct/Create.vue'),
        beforeEnter: middleware([authentication, isSeller]),
      }
    ]
  },
  {
    path: '/produtos/edicao-produto/:id_product/:code?',
    name: 'EditProduct',
    component: () => import('../../views/Seller/UpdateProduct/index.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/extrato',
    name: 'Statements',
    component: () => import('../../views/Seller/Statements.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/afiliados',
    name: 'Affiliation',
    component: () => import('../../views/Seller/Affiliation.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },

  {
    path: '/links',
    name: 'Links',
    component: () => import('../../views/Seller/Links.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/checkouts',
    name: 'Checkouts',
    component: () => import('../../views/Seller/Checkouts.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
   {
     path: '/cupom',
     name: 'Coupon',
     component: () => import('../../views/Seller/Cupom.vue'),
     beforeEnter: middleware([authentication, isSeller]),
   },
  {
    path: '/configuracoes',
    name: 'Settings',
    component: () => import('../../views/Seller/Settings.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/minha-conta',
    name: 'MyAccount',
    component: () => import('../../views/Seller/MyAccount.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/definir-senha/:token',
    name: 'SetupPassword',
    component: () => import('../../views/Seller/SetupPassword.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  // Client
  {
    path: '/cliente/registro',
    name: 'Client-Register',
    component: () => import('../../views/Client/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/compras',
    name: 'Client-Purchases',
    component: () => import('../../views/Client/Purchases.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/assinaturas',
    name: 'Client-Subscriptions',
    component: () => import('../../views/Client/Subscriptions.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/embaixador',
    name: 'Ambassador',
    component: () => import("../../views/Seller/Ambassador/Ambassador.vue"),
    beforeEnter: middleware([authentication, isAmbassador, isSeller]),
  },
  {
    path: '/redirect/view-ticket/:hash',
    name: 'ViewTicket',
    component: () => import("../../views/Client/ViewTicket.vue"),
    meta: {
      sidebar: false
    }
  },
  // Common
  {
    name: 'AdminLogin',
    path: '/admin-login/:userid',
    component: () => import('../../views/AdminLogin.vue'),
  },
  {
    name: 'Redirect',
    path: '/redirect/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'Redirect',
    path: '/g/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'AffiliationInvite',
    path: '/afiliacao-solicitacao/:token',
    component: () => import('../../views/AffiliationInvite.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  // {
  //   path: '/vitrine',
  //   name: 'Vitrine',
  //   component: () => import('../../views/Seller/Store.vue'),
  //   beforeEnter: middleware([authentication, isSeller]),
  // },
  {
    path: '/central-de-notas',
    name: 'NoteCenter',
    component: () => import('../../views/Seller/NoteCenter.vue'),
    beforeEnter: middleware([authentication, isHeaven, isSeller]),
  },
  {
    path: '/login-to-google/:auth/:title',
    name: 'Login Google',
    component: () =>
        import ('../../views/Seller/LoginGoogleOff.vue'),
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import('../../views/Seller/Reports.vue'),
    beforeEnter: middleware([authentication, isHeaven, isSeller]),
  },
  {
    path: '/gestao-leads',
    name: 'Leads',
    component: () => import('../../views/Seller/Leads.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/reclamacoes',
    name: 'Reclamações',
    component: () => import('../../views/Seller/Claim.vue'),
    beforeEnter: middleware([authentication, isSeller]),
  },
  {
    path: '/indications',
    name: 'Indicacoes',
    component: () => import('../../views/Seller/Indications.vue'),
    beforeEnter: middleware([authentication, isSeller, isHeaven, isIndicationEnable]),
  },
  {
    path: '/tickets_form/:sale_id',
    name: 'Resgate Ingresso',
    component: () => import('../../views/Client/TicketsForm.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../../views/PageNotFound.vue'),
  },
  {
    name: 'Download App',
    path: '/app',
    component: () => import('../../views/DownloadApp.vue'),
  },
  {
    path: '/recibos/cartoes/:id',
    name: 'ReciboCartoes',
    component: () => import('../../components/Seller/Sales/modals/pdfs/CartoesPdf.vue'),
  },
  {
    path: '/recibos/pix/:id',
    name: 'ReciboPix',
    component: () => import('../../components/Seller/Sales/modals/pdfs/PixPdf.vue'),
  },
  {
    path: '/recibos/boletoS/:id',
    name: 'ReciboBoletos',
    component: () => import('../../components/Seller/Sales/modals/pdfs/BoletoPdf.vue'),
  },
  {
    name: 'dsa',
    path: '/dsa',
    component: () => import('../../views/PageDSA.vue'),
    beforeEnter: middleware([authentication, isHeaven, isSeller]),
  },
]
