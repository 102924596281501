<script setup>
import { computed } from "vue";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();

const props = defineProps({
  name: {
    type: String,
    default: "Defina o nome a ser exibido",
  },
  route: {
    type: String,
    default: "Home",
  },
  is_new: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  traduction: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

const translateName = computed(() => {
  return props.traduction && props.traduction.length
    ? i18n.t(props.traduction)
    : props.name;
});

function emitClick() {
  emit("click", props.name);
}
</script>
<template>
  <div class="item" @click="emitClick">
    <router-link :class="{ active: props.active, 'is-new': props.is_new }" :to="{ name: props.route }">
      <slot />
      <h1 :class="{ hover: props.hover, 'is-new': props.is_new }">{{ translateName }}</h1>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.item {
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--spacing-6);
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    transition: var(--transition);
    padding: 0 0 0 var(--spacing-4);
    border-radius: var(--radius-lg);
    height: 48px;
    :visited {
      color: inherit;
    }

    &:focus-visible {
      transition: none;
      outline: 1.5px solid var(--old-500);
    }

    &:hover {
      background: var(--gray-10) !important;
    }

    h1 {
      color: var(--gray-300);
      font-size: var(--font-md);
      font-weight: var(--weight-medium);
      overflow: hidden;
      text-overflow: ellipsis;

      &.hover {
        animation: fadeEnter 1s ease;
      }

      &:not(.hover) {
        animation: fadeExit 1s ease;
      }

      @media (max-width: 768px) {
        animation: none !important;
      }
    }

    &.active {
      background-color: var(--old-50) !important;
      h1 {
        color: var(--old-500);
        font-weight: var(--weight-semibold);
        &.is-new
        {
          color: #2469EE;
        }
      }
    }
  }
  transition: var(--transition);
  &.is-new
  {
    color: #2469EE;
  }
}

@keyframes fadeEnter {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
}

@keyframes fadeExit {
  0% {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
  70% {
    width: 0;
    max-width: 0;
    overflow: hidden;
  }

  100% {
    opacity: 0;
  }
}
</style>
