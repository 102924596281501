import OpportunitiesSettingsService from "@/services/resources/OpportunitiesSettingsService";
const serviceOpportunitiesSettings = OpportunitiesSettingsService.build();

export default {
  async setActiveProfit({ commit, getters, rootGetters, dispatch }, item) {
    try {
      commit("setLoadingSettings", true);

      let { productId } = rootGetters;
      let { getGreennProfit } = getters;

      if (getGreennProfit) {
        await serviceOpportunitiesSettings
          .createId({
            id: "/softChange",
            profitToggle: item,
            productId: productId,
          })
          .then((r) => {
            if (r.messages) {
              commit("updateOpportunitiesSettings", r.messages);
              dispatch("getOpportunitiesSettings");
            }
            commit("updateOpportunitiesSettings", []);
          })
          .finally(() => {
            commit("setLoadingSettings", false);
          });
        return;
      }

      let { getOpportunitiesSettings, getDefaultSettings } = getters;

      if (!getOpportunitiesSettings.length) {
        await serviceOpportunitiesSettings
          .createId({
            id: "/save",
            productId: productId,
            settings: getDefaultSettings,
          })
          .then((r) => {
            dispatch("getOpportunitiesSettings");
          })
          .finally(() => {
            commit("setLoadingSettings", false);
          });
      }
    } catch (error) {
      commit("setLoadingSettings", false);
      console.error(error);
    }
  },
  async getOpportunitiesSettings({ commit, rootGetters, dispatch }) {
    try {
      await serviceOpportunitiesSettings
        .read({ id: rootGetters.productId })
        .then((resp) => {
          if (resp.messages) {
            if (resp.messages.opportunity_settings && resp.messages.opportunity_settings.length) {
              commit("updateOpportunitiesSettings", resp.messages.opportunity_settings);
              commit("setActiveProfit", true);
            } else {
              commit("setActiveProfit", false);
              dispatch("getStatusProfitGlobal");
            }

            if (resp.messages.product_questions.length) {
              commit("setLoadingQuestions", false);
              commit("setQuestionsAnswers", resp.messages.product_questions.reverse());
            }
          }
        })
        .catch((err) => console.error("err", err));
    } catch (error) {
      console.error(error);
    }
  },

  async getStatusProfitGlobal({ dispatch, commit }) {
    const resp = await dispatch("opportunities/getKittenTimeSettings", null, { root: true });
    commit("setTimeGlobal", resp);
  },

  async setOpportunitySettings({ commit }, item) {
    commit("updateOpportunitiesSettings", item);
  },
  async editQuestionInList({ commit, getters }, item) {
    let { opportunitiesQuestionsAnswers } = getters;

    let questionsList = opportunitiesQuestionsAnswers.filter((item) => item.id != questionId);

    commit("setQuestionsAnswers", questionsList);
  },
  async addQuestionList({ commit, getters }, question) {
    let { getOpportunitiesQuestionsAnswers } = getters;

    let newQuestionsList = [question, ...getOpportunitiesQuestionsAnswers];

    commit("setQuestionsAnswers", newQuestionsList);
  },
  async dropQuestionList({ commit, getters }, questionId) {
    let { getOpportunitiesQuestionsAnswers } = getters;

    let questionsList = getOpportunitiesQuestionsAnswers.filter((item) => item.id != questionId);

    commit("setQuestionsAnswers", questionsList);
  },
  async saveOpportunitySettings({ rootGetters }, data) {
    try {
      const settings = data.settings.map((item) => {
        const { recovery_time, ...rest } = item;
        return {
          ...rest,
          recoveryTime: recovery_time || null,
        };
      });

      if (settings.length) {
        await serviceOpportunitiesSettings.createId({
          id: "/save",
          productId: rootGetters.productId,
          settings,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async activeLoadingQuestion({ rootGetters, commit }, data) {
    commit("setLoadingQuestions", data);
  },
};
