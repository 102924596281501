<script setup>
import { ref, provide, computed, watch, onMounted, onUnmounted } from "vue";

const activeTabHeader = ref(1);
const refsToHeaderItems = ref([]);

const emits = defineEmits(["activeTabValue"]);

const dynamicGlider = computed(() => {
  if (activeTabHeader.value && refsToHeaderItems.value.length) {
    let translateX = 0;

    // Somando a largura dos elementos anteriores ao activeTabHeader
    for (let i = 0; i < activeTabHeader.value - 1; i++) {
      translateX += refsToHeaderItems.value[i].width + 16;
    }

    const width = refsToHeaderItems.value[activeTabHeader.value - 1].width + 16;

    return `transform: translate(${
      translateX - 8
    }px, -50%); width: ${width}px;`;
  }

  return "transform: translate(0px, -50%); width: 0px;";
});

watch(activeTabHeader, (newValue) => {
  emits("activeTabValue", newValue);
});

function getRefToTabHeaderItems(refs) {
  refsToHeaderItems.value.push(refs);
}

function setActiveTabHeader(tabIndex) {
  activeTabHeader.value = tabIndex;
}

provide("getRefToTabHeaderItems", getRefToTabHeaderItems);
provide("setActiveTabHeader", setActiveTabHeader);
provide("indexActiveTabHeader", activeTabHeader);

onUnmounted(() => {
  emits("activeTabValue", activeTabHeader.value);
});
</script>

<template>
  <div class="gr-tabs">
    <slot />
    <span class="gr-glider" :style="dynamicGlider"></span>
  </div>
</template>

<style scoped lang="scss">
.gr-tabs {
  display: flex;
  gap: 16px;
  position: relative;
  background-color: var(--gray-10);
  padding: 12px;
  border-radius: 10px;
  width: fit-content;
  height: 50px;

  * {
    z-index: 2;
  }

  .gr-glider {
    position: absolute;
    display: flex;
    height: 54px;
    width: 100px;
    background-color: var(--dark-500);
    z-index: 1;
    border-radius: 10px;
    transition: 0.25s ease-out;
    will-change: transform;
    height: 36px;
    top: 50%;
  }
}
</style>
