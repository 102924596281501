<script setup>
import { onMounted, ref, inject } from "vue";

const getRefToTabHeaderItems = inject("getRefToTabHeaderItems");

if (!getRefToTabHeaderItems) {
  throw new Error("getRefToTabHeaderItems not provided");
}

const setActiveTabHeader = inject("setActiveTabHeader");

if (!setActiveTabHeader) {
  throw new Error("setActiveTabHeader not provided");
}

const indexActiveTabHeader = inject("indexActiveTabHeader");

if (!indexActiveTabHeader) {
  throw new Error("indexActiveTabHeader not provided");
}

const props = defineProps({
  id: {
    type: Number,
    default: 0,
    required: true,
  },
});

const emit = defineEmits(["updateWidth"]);

const grTabHeaderItem = ref(null);

onMounted(() => {
  if (grTabHeaderItem.value) {
    const width = grTabHeaderItem.value.offsetWidth;
    getRefToTabHeaderItems({ id: props.id, width });
  }
});
</script>

<template>
  <div ref="grTabHeaderItem" @click.prevent.stop="setActiveTabHeader(props.id)">
    <input
      type="radio"
      :id="`radio-${props.id}`"
      name="gr-tabs"
      :checked="props.id === indexActiveTabHeader"
    />
    <label class="gr-tab" for="radio-1">
      <slot />
    </label>
  </div>
</template>

<style scoped lang="scss">
input {
  all: none !important;
}

input[type="radio"] {
  display: none;

  &:checked {
    & + label {
      color: var(--gray-white);
    }
  }
}

.gr-tabs {
  display: flex;
  gap: 16px;
  position: relative;
  background-color: var(--gray-10);
  padding: 0.75rem;
  border: 10px;
  margin: 0 !important;

  * {
    z-index: 2;
  }
}

.gr-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin: 0 !important;
  color: var(--gray-200);
}
</style>
