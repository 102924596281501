import { render, staticRenderFns } from "./iconMegaPhone.vue?vue&type=template&id=3728440c&scoped=true"
import script from "./iconMegaPhone.vue?vue&type=script&setup=true&lang=js"
export * from "./iconMegaPhone.vue?vue&type=script&setup=true&lang=js"
import style0 from "./iconMegaPhone.vue?vue&type=style&index=0&id=3728440c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3728440c",
  null
  
)

export default component.exports