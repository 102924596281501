import { textMethodType } from '@/helpers.js'
export default {
  setProduct(state, payload) {
    if(!payload.address) {
      payload.address = {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null
      }
    }
    state.product = payload;
  },
  setProductBeforeEdit(state, payload){
    if(!payload.address) {
      payload.address = {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null
      }
    }
    state.productBeforeEdit = payload;
  },
  setProductStatus(state, status) {
    state.product.status_product = status;
  },
  setTimeoutId(state, timeoutId) {
    if (timeoutId === null) {
      clearTimeout(state.timeoutStatus);
      clearTimeout(timeoutId);
      state.timeoutStatus = null;
    }
    state.timeoutStatus = timeoutId;
  },
  setFetchingStatus(state, fetchingStatus) {
    state.fetchingStatus = fetchingStatus;
  },
  setProductStartDate(state, payload) { state.product.start_date = payload },
  setProductStartTime(state, payload) { state.product.start_time = payload },
  setProductEndDate(state, payload) { state.product.end_date = payload },
  setProductEndTime(state, payload) { state.product.end_time = payload },
  setProductZipCode(state, payload) { state.product.address.zip_code = payload },
  setProductNumber(state, payload) { state.product.address.number = payload },
  setProductStreet(state, payload) { state.product.address.street = payload },
  setProductNeighborhood(state, payload) { state.product.address.neighborhood = payload },
  setProductCity(state, payload) { state.product.address.city = payload },
  setProductState(state, payload) { state.product.address.state = payload },
  setNeedOfferLoading(state, payload) { state.need_offer_loading = payload },
  setProductComplement(state, payload) { state.product.address.complement = payload },
  setProductLocation(state, payload) { state.product.location = payload },
  setProductAreNonTransferable(state, payload) { state.product.are_non_transferable = payload },
  setImages(state, payload) { state.product.images = payload },
  setCategories(state, payload) { state.categories = payload },
  setIsLoading(state, payload) { state.isLoading = payload },
  setSaleExpressRedirectLoading(state, payload) { state.saleExpressRedirectLoading = payload },
  setAffiliateLoading(state, payload) { state.affiliateLoading = payload },
  setAffiliateLink(state, payload) { state.affiliateLink = payload },
  setCoSellers: (state, payload) => { state.coSellers = payload },
  addCoSellers: (state, payload) => { 
    let dataCoSeller = [];
    for (let i = 0; i < payload.length; i++) {
      dataCoSeller.unshift({
        id: payload[i].id,
        user_id: payload[i].user_id.id,
        percentage: parseFloat(String(payload[i].percentage).replace(",", ".")),
        type: payload[i].type,
        user: {
          name: payload[i].user.name,
          email: payload[i].user_id.email,
        },
      });
    }
    let coSellers = [...state.coSellers];
    coSellers.push(dataCoSeller[0]);
    state.coSellers = coSellers ;
    state.product.co_sellers = coSellers ;
  },
  setCoSellersPercentage: (state, payload) => { state.coSellersPercentage = payload },
  setOffers: (state, payload) => { state.offers = payload },
  filterDefaultOffer: (state, payload) => {
    const findDefault = payload.find(x => x.default === 1)
    state.defaultOffer = findDefault
  },
  setDefaultOffer(state, payload) {
    if(!payload) return;
    if(!payload.max_boleto_installments) payload.max_boleto_installments = 0;
    if(!!payload.max_boleto_installments && payload.max_boleto_installments > 1 && payload.max_boleto_installments < 13 ) state.validateInstallmentBoleto = false
    else if(payload.max_boleto_installments === null || payload.max_boleto_installments === undefined) state.validateInstallmentBoleto = false
    state.defaultOffer = payload
   },
  setHideUpdateButton: (state, payload) => { state.hideUpdateButton = payload },
  setAttachments: (state, payload) => { state.product.attachments = payload },
  setCustomCheckout: (state, payload) => { state.product.custom_thank_you_pages = payload },
  setNewThankYouPage: (state, payload) => { state.custom_thank_you_pages = payload },
  setFiscalCenterSetting: (state, payload) => { state.product.fiscal_center_setting = payload },
  setGenerateAfterWarranty: (state, payload) => { state.product.fiscal_center_setting.generate_after_warranty = payload },
  serCoproducerWillIssueNote: (state, payload) => { state.product.fiscal_center_setting.co_producer_will_issue_note = payload },
  setSmartSale: (state, payload) => { state.allowSmartSale = payload },
  setExportSales: (state, payload) => { state.exportSales = Boolean(Number(payload)) },
  setBaseAmount: (state, payload) => { state.baseAmount = payload },
  setCustomPeriod: (state, payload) => { state.allowCustomPeriod = payload },
  setCallValidateFields: (state, payload) => { state.callValidateFields = payload },
  setCallValidateFieldsAddressEvent: (state, payload) => { state.callValidateFieldsAdrressEvent = payload },
  setCallValidateFieldsDateEvent: (state, payload) => { state.callValidateFieldsDateEvent = payload },
  setPixels: (state, payload) => { state.product.pixels = payload },
  setCountryCode(state, payload) { state.countryCode = payload },
  setAllowFreeOffers(state, payload) { state.allow_free_offers = payload },
  setKycPersonalChecklist(state, payload) { state.kyc_personal_checklist = payload },
  clearErrorCreateCelcoin(state){ state.errorCreateCelcoin = [] },
  addErrorCreateCelcoin(state, payload) {
    state.errorCreateCelcoin.push(payload);
   },
  clearErrorKycCelcoin(state){ state.errorKycCelcoin = [] },
  addErrorKycCelcoin(state, payload) {
    if (payload.length < 3) return
    const pf_pj = payload.slice(-3);
    if(pf_pj === '_PF') state.errorKycCelcoinPF.push(payload)
    if(pf_pj === '_PJ') state.errorKycCelcoinPJ.push(payload)
  },
  setEnableInvoiceItems(state, payload) {
    state.product.enable_invoice_items = payload;
  },
  setItemsForInvoice(state, payload) {
    let arr = []
    payload.forEach(x => {
      arr.push(Object.assign({}, x))
    })
    state.product.items_for_invoice = arr
  },
  setOfferRedirect(state, payload) {
    state.product.offer_redirect = payload;
  },
  setMethodsPayment(state, payload) {
    const that = payload.this;

    const textMethod = method => {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD":
            return that.$t("views.seller.edit_product.text_1112");
          case "CREDIT-CARD":
            return that.$t("views.seller.edit_product.text_1112");
          case "BANKTRANSFER":
            return that.$t("views.seller.edit_product.text_1113");
          case "CREDITCARD":
            return that.$t("views.seller.edit_product.text_1112");
          case "DEBITCARD":
            return that.$t("views.seller.edit_product.text_1114");
          case "debitcard":
            return that.$t("views.seller.edit_product.text_1114");
          case "TWO_CREDIT_CARDS":
            return that.$t("views.seller.edit_product.text_1115");
          case "DEBITCARD":
            return "CARTÃO DE DÉBITO";
          case "EFT":
            return "EFT";
          case "SPEI":
            return "SPEI";
          case "SENCILLITO":
            return "SENCILLITO";
          case "OXXO":
            return "OXXO";
          default:
            return method;
        }
      } else return method;
    }
    let product = Object.assign({},payload.getters.product);
    const eventTickets = () => {
      if(payload.getters.getFlags.includes("feature_tickets") && product.product_type_id == 3) return true;
      return false
    }
    const methods = [];

    payload.response.forEach((element) => {
      if((element.payment_methods.name === 'credit_card' ||
          element.payment_methods.name === 'boleto' ||
          element.payment_methods.name === 'pix') && eventTickets()){
        methods.push({
          value: textMethodType(element.payment_methods.name).toUpperCase(),
          name: textMethod(element.payment_methods.name).toUpperCase()
        })
      }
    })

    state.methodsPayment = methods;
  },
  setCurrencyMethodPayment(state, paylod) {
    const findOffer = state.offers.find((x) => x.default === 1);
    const methodItem = findOffer.method.split(",");
    var methodAll = state.methodsPayment;
    var arrayMethod = [];

    if (methodItem[0] === "ALL") {
      for (let i = 0; i < methodAll.length; i++) {
        arrayMethod.push(methodAll[i]);
      }
    } else {
      for (let i = 0; i < methodAll.length; i++) {
        const elementAll = methodAll[i];
        for (let x = 0; x < methodItem.length; x++) {
          if (elementAll.value === methodItem[x]) {
            if (
              state.product.type === "SUBSCRIPTION" &&
              (elementAll.value === "CREDIT_CARD" ||
                elementAll.value === "BOLETO")
            ) {
              arrayMethod.push(elementAll);
            } else {
              arrayMethod.push(elementAll);
            }
          }
        }
      }
    }

    state.currencyPayment = arrayMethod;
  },
  updateCurrencyMethodPayment(state, paylod) {
    state.currencyPayment = paylod;
  },
  setEventFildsResult(state, payload) {
    state.eventFildsResult = payload
  },
  // Content
  setWebhooks(state, payload) {
    const that = payload.this;

    const convertTrigger = trigger => {
      if (trigger) {
          switch (trigger) {
            //  vendas
            case "salePaid":
              return that.$t("seller.products.options_sales.text_751");
            case "saleRefused":
              return that.$t("seller.products.options_sales.text_752");
            case "saleRefunded":
              return that.$t("seller.products.options_sales.text_753");
            case "BOLETO":
              return that.$t("seller.products.options_saleswaiting.text_758");
            case "PIX":
              return that.$t("seller.products.options_saleswaiting.text_759");
            case "saleChargedback":
              return that.$t("seller.products.options_sales.text_754");
            case "saleUnpaid":
              return that.$t("seller.products.options_sales.text_755");
            case "saleWaitingPayment":
              return that.$t("seller.products.options_sales.text_756");

            //  assinaturas
            case "contractUnpaid":
              return that.$t("seller.products.options_sub.text_773");
            case "contractPaid":
              return that.$t("seller.products.options_sub.text_774");
            case "contractTrialing":
              return that.$t("seller.products.options_sub.text_775");
            case "contractPendingPayment":
              return that.$t("seller.products.options_sub.text_776");
            case "contractEnded":
              return that.$t("seller.products.options_sub.text_777");
            case "contractCanceled":
              return that.$t("seller.products.options_sub.text_778");

            //  checkout
            case "checkoutAbandoned":
              return that.$t("seller.products.options_checkout.text_779");

            //  venda recusada
            case "INVALID_CREDIT_CARD":
              return that.$t("seller.products.options_salesrefused.text_761");
            case "INSUFFICIENT_FUNDS":
              return that.$t("seller.products.options_salesrefused.text_762");
            case "INVALID_PAYMENT_TYPE":
              return that.$t("seller.products.options_salesrefused.text_763");
            case "INVALID_DATA":
              return that.$t("seller.products.options_salesrefused.text_764");
            case "INVALID_CLIENT_DATA":
              return that.$t("seller.products.options_salesrefused.text_765");
            case "INVALID_CVV":
              return that.$t("seller.products.options_salesrefused.text_766");
            case "BANK":
              return that.$t("seller.products.options_salesrefused.text_767");
            case "CREDIT_CARD_OPERATOR":
              return that.$t("seller.products.options_salesrefused.text_768");
            case "INVALID_INSTALLMENTS":
              return that.$t("seller.products.options_salesrefused.text_769");
            case "CURRENCY_NOT_SUPPORTED":
              return that.$t("seller.products.options_salesrefused.text_770");
            case "SUSPECTED_FRAUD":
              return that.$t("seller.products.options_salesrefused.text_771");
            case "GENERIC":
              return that.$t("seller.products.options_salesrefused.text_772");
            default:
              return trigger;
          }
        } else return trigger;
    }

    let lista = [];
    let r = payload.response;
    [...r].reverse().forEach((item) => {
      if (item.type == "webhook") {
        lista.push({
          name: convertTrigger(item.trigger),
          sub_trigger: convertTrigger(item.sub_trigger),
          url: JSON.parse(item.args).url,
          id: item.id,
          obj: item,
        });
      }
    });

    state.webhooks = lista;
  },
  setEventFildsResult(state, payload) {
    state.eventFildsResult = payload;
  },
  setProductTermsContracts(state, payload) {
    state.product.new_contract_terms = payload;
  },
};
