<script setup>
import { computed, inject, onBeforeMount } from "vue";

const props = defineProps({
  index: {
    type: Number,
    default: 0,
    required: false,
  },
  qaId: {
    type: String,
    default: "action_open_collapsible",
  },
});

const emit = defineEmits(["change"]);

const isActive = computed(() => activeCollapsible.value === props.index);

function toggle(e) {
  if (e.target.classList.contains("skip-charge")) {
    return;
  }

  setActiveCollapsible(isActive.value ? null : props.index);
  emit("change", { index: props.index, value: isActive.value });
}

function getStateAccordionDefault() {
  if (getDefaultActive) {
    setActiveCollapsible(getDefaultActive);
  }
}

onBeforeMount(() => {
  getStateAccordionDefault();
});

const activeCollapsible = inject("activeCollapsible");
const setActiveCollapsible = inject("setActiveCollapsible");
const getDefaultActive = inject("getDefaultActive");
</script>

<template>
  <div @click="toggle" class="gr-collapse-item" :class="{ active: isActive }">
    <div class="gr-collapse-header-content">
      <slot name="header"></slot>
      <svg
        :id="qaId"
        class="icon"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13L7 7L1 1"
          class="dark-500-stroke" 
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="gr-collapse-content" @click.stop>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gray-200-stroke { stroke: var(--gray-200); } 
.gray-200-fill { fill: var(--gray-200); }
.gr-collapse-item {
  position: relative;
  width: var(--w-full);
  border-radius: 10px;
  border: 1px solid #e5e7e7;
  padding: 24px 32px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  .gr-collapse-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    transition: all 0.2s ease-in-out;
    transform: rotate(90deg);
  }

  &.active {
    .icon {
      transform: rotate(-90deg);
    }

    .gr-collapse-content {
      max-height: var(--w-full);

      animation: show 0.3s both;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gr-collapse-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  cursor: text;
  user-select: text;
}
</style>
