<script setup>
import ModalTrigger from "./ModalTrigger.vue";
import { GrTeleport } from "@/components/GrComponents";

import { ref, provide, watch } from "vue";
import { useKeyboardShortcut } from "./events";

const props = defineProps({
  close: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  renderTriggerTo: {
    type: String,
    default: "",
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltipMessageDisabled: {
    type: String,
  },
});

const emits = defineEmits(["update:modelValue"]);

const isOpen = ref(props.modelValue);

function closeModal() {
  if (!props.close) return;
  isOpen.value = false;
  emits("update:modelValue", false);
}
useKeyboardShortcut("Escape", closeModal);

function openModal() {
  if (props.disabled) return;
  isOpen.value = true;
  emits("update:modelValue", true);
}

watch(
  () => props.modelValue,
  (newVal) => {
    isOpen.value = newVal;
  }
);

provide("close", closeModal);
provide("canClose", ref(props.close));
provide("open", openModal);
</script>

<template>
  <!-- Render modal in body -->
  <GrTeleport to="body">
    <!-- Render trigger in specific id -->
    <GrTeleport v-if="renderTriggerTo.length" :to="props.renderTriggerTo">
      <ModalTrigger
        :disabled="props.disabled"
        :tooltipMessageDisabled="props.tooltipMessageDisabled"
      >
        <slot name="open" />
      </ModalTrigger>
    </GrTeleport>

    <Transition name="fade">
      <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
        <slot />
      </div>
    </Transition>
  </GrTeleport>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-from {
  opacity: 0;
  animation: scale-bottom-enter 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
}

.fade-leave-to {
  opacity: 0;
  animation: scale-bottom-leave 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
}

/* Keyframes corrigidos e definidos globalmente */
@keyframes scale-bottom-enter {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scale-bottom-leave {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.fade-enter-to {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 1000vh;
    height: 1000vh;
  }

  animation: show 0.2s linear;

  @keyframes show {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }

  @include size(md) {
    align-items: end;
    height: 100dvh;
  }
}
</style>
