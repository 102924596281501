import ProductQuestion from "@/services/resources/ProductQuestion";
const serviceQuestion = ProductQuestion.build();

export default {
  setProductsQuestion: ({ commit }, products) => {
    commit("upDateProductsMakeQustion", products);
  },
  setTextQuestion: ({ commit }, text) => {
    commit("upDateTextQuestion", text);
  },
  setTextResponse: ({ commit }, text) => {
    commit("upDateTextResponse", text);
  },
  validMakeQuestion: ({ commit, state }) => {
    const hasProduct = state.makeQuestion.products.length;

    let textQuestions = state.makeQuestion.question.replace(/\s/g, "").length;
    let textAnswers = state.makeQuestion.response.replace(/\s/g, "").length;

    const hasQuestion = textQuestions >= 5 && textQuestions <= 200;
    const hasResponse = textAnswers >= 2 && textAnswers <= 600;

    const requireds = {
      products: false,
      question: false,
      response: false,
    };

    if (!hasProduct) requireds.products = true;
    if (!hasQuestion) requireds.question = true;
    if (!hasResponse) requireds.response = true;

    return requireds;
  },
  setPostQuestion: async ({ commit, state, dispatch, rootGetters }, addInList) => {
    let { productId } = rootGetters;

    const data = {
      productsIds: state.makeQuestion.products.map((product) => product.id),
      questions: [
        {
          question: state.makeQuestion.question,
          answer: state.makeQuestion.response,
        },
      ],
    };
    try {
      const response = await serviceQuestion.create(data);
      if (response.status == "SUCCESS") {
        if (addInList) {
          return {
            id: response.messages.productQuestions[productId][0],
            question: state.makeQuestion.question,
            answer: state.makeQuestion.response,
          };
        }
        await dispatch("makeQuestionsFronList", {
          productsIds: data.productsIds,
          response: response.messages.productQuestions,
        });
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  setNextPage: ({ commit, getters }) => {
    commit("updateCurrentPage", getters["getPagination"].currentPage + 1);
  },
  
  setDeleteQuestion: async ({ commit, state, dispatch, getters }, data) => {
    console.log({data});
    try {
      const response = await serviceQuestion.destroy({ id: data.questionId });

      if (response.status == "SUCCESS") {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  makeQuestionsFronList: ({ getters, commit, dispatch }, { productsIds, response }) => {
    const dataDropOfList = productsIds.map((id) => {
      const productUsed = getters["getMakeQuestion"].products.find((product) => product.id === id);
      const findIdquestion = Object.entries(response).find(([key]) => Number(key) === id);

      return {
        id,
        name: productUsed.name,
        main_image: {
          path: productUsed.images[0]?.path ?? false,
        },
        product_questions: [
          {
            id: findIdquestion[1][0],
            question: getters["getMakeQuestion"].question,
            answer: getters["getMakeQuestion"].response,
            product_id: id,
          },
        ],
      };
    });

    commit("updateQuestionList", dataDropOfList);
    dispatch("setClearQuestions");
  },
  getQuestionList: async (
    { commit },
    pagination = { perPage: 10, currentPage: 1, totalPages: 2 }
  ) => {
    if (pagination.currentPage > pagination.totalPages) return;
    try {
      const { messages } = await serviceQuestion.get(
        `?page=${pagination.currentPage}&perPage=${pagination.perPage}`
      );
      commit("updateQuestionList", messages.items);
      commit("updateTotal", messages.total);
      commit("updateTotalPages", messages.lastPage);
      return messages;
    } catch (error) {
      console.error(error);
    }
  },
  setClearQuestions: ({ commit }) => {
    commit("upDateProductsMakeQustion", []);
    commit("upDateTextQuestion", "");
    commit("upDateTextResponse", "");
  },
  async updateQuestionAnswer({ _ }, data) {
    let textQuestions = data.question.replace(/\s/g, "").length;
    let textAnswers = data.answer.replace(/\s/g, "").length;

    const hasQuestion = textQuestions >= 5 && textQuestions <= 200;
    const hasResponse = textAnswers >= 2 && textAnswers <= 600;

    if (!hasQuestion || !hasResponse)
      return {
        notValidate: true,
        message: !hasQuestion
          ? "opportunities.modal.validateHasQuestion"
          : !hasResponse
          ? "opportunities.modal.validateHasResponse"
          : "",
      };
    try {
      const resp = await serviceQuestion.update(
        {
          productId: data.product_id,
          question: data.question,
          questionId: data.id,
          answer: data.answer,
        },
        data.id
      );
      if (resp.status == "SUCCESS") {
        return data;
      }
      throw new Error("não foi possivel atualizar");
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async generateByIa({ dispatch, rootGetters, commit }, text) {
    let { productId } = rootGetters;
    try {
      await serviceQuestion.createId({ id: `/ia/${productId}` });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async deletQuestion({ commit }, data) {
    try {
      const resp = await serviceQuestion.delete(`${data.id}`);
      if (resp.status == "SUCCESS") {
        commit("updateListAfterDelet", data);
        return true;
      }
      throw new Error("Não foi possivel deletar");
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async updateUniqueQuestion({ commit }, data) {
    await commit("updateUniqueQuestion", data);
  },
};
