import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  product: {
    name: null,
    description: "",
    amount: 0,
    charges: null,
    type: null,
    format: null,
    banner: [],
    period: 30,
    trial: null,
    has_stock: false,
    stock: 0,
    category_id: null,
    warranty: null,
    warranty_checkout: true,
    method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
    allowed_coupon: false,
    is_checkout_address: false,
    allow_proposal: true,
    affiliation: 0,
    affiliation_approbation: 0,
    affiliation_public: 0,
    affiliate_proposal: 0,
    comission: null,
    sales_page: null,
    thank_you_page: null,
    url_callback: null,
    bump_product_id: null,
    proposal_minimum: null,
    release: {},
    images: [],
    metas: [],
    pixels: [],
    co_sellers: [],
    address: {
      zip_code: null,
      number: null,
      street: null,
      neighborhood: null,
      city: null,
      state: null,
      complement: null,
    },
    location: null,
    are_non_transferable: true,
    max_paid_sales: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    release_type: null,
    release_offer_group_stock_id: null,
    release_fixed_date: null,
  },
  need_offer_loading : true,
  allowSmartSale: false,
  exportSales: false,
  allowCustomPeriod: false,
  categories: [],
  affiliateLink: "",
  offers: [],
  defaultOffer:{},
  baseAmount: 0,
  coSellers: [],
  coSellersPercentage: 0,
  custom_thank_you_pages: [],
  hideUpdateButton: false,
  callValidateFields: false,
  callValidateFieldsAdrressEvent:false,
  callValidateFieldsDateEvent:false,
  validateInstallmentBoleto: false,
  countryCode: "BR",
  // Erros
  errorInFetchProduct: false,
  // Loadings
  isLoading: false,
  saleExpressRedirectLoading: false,
  affiliateLoading: false,
  listLoading: false,
  allow_free_offers: null,
  kyc_personal_checklist: null,
  errorCreateCelcoin: [],
  errorKycCelcoinPF: [],
  errorKycCelcoinPJ: [],
  // Payment
  methodsPayment: [],
  currencyPayment: [],

  eventFildsResult: false,
  // Contents
  webhooks:[],
  // time out status
  timeoutStatus: null,
  fetchingStatus: false,
  productBeforeEdit: {
    name: null,
    description: "",
    amount: 0,
    charges: null,
    type: null,
    format: null,
    banner: [],
    period: 30,
    trial: null,
    has_stock: false,
    stock: 0,
    category_id: null,
    warranty: null,
    warranty_checkout: true,
    method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
    allowed_coupon: false,
    is_checkout_address: false,
    allow_proposal: true,
    affiliation: 0,
    affiliation_approbation: 0,
    affiliation_public: 0,
    affiliate_proposal: 0,
    comission: null,
    sales_page: null,
    thank_you_page: null,
    url_callback: null,
    bump_product_id: null,
    proposal_minimum: null,
    release: {},
    images: [],
    metas: [],
    pixels: [],
    co_sellers: [],
    address: {
      zip_code: null,
      number: null,
      street: null,
      neighborhood: null,
      city: null,
      state: null,
      complement: null,
    },
    location: null,
    are_non_transferable: true,
    max_paid_sales: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    release_type: null,
    release_offer_group_stock_id: null,
    release_fixed_date: null,
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
